/* global digitalData, _satellite */

/**
 * @function initFormTracking
 * @description Initializes the event listeners for handling form interactions, primarily when a form is started and successfully completed
 * @param {JQuery} $specificForm - (Optional) JQuery object representing the form element that triggered the event
 */
function initFormTracking($specificForm) {
    if (typeof digitalData === 'object') {
        const $form = $specificForm || $('form');
        let formName;

        $form
            .on('focusin', function () {
                let $currentElement = $(this);
                formName = $currentElement.data('dtm-name');

                if (digitalData.page.form && digitalData.page.form.formName !== formName) {
                    digitalData.page.form.formName = formName;
                    if (typeof _satellite !== 'undefined') {
                        // Cloning digitalData so the event has the state at the moment it was fired
                        _satellite.track('formStart', { digitalData: window.digitalData ? JSON.parse(JSON.stringify(window.digitalData)) : undefined });
                    }
                } else if (!digitalData.page.form) {
                    digitalData.page.form = {
                        formName: formName
                    };
                }
            })
            .on('submit', function () {
                if (digitalData.page.form && digitalData.page.form.formName && this.checkValidity()) {
                    $(document).on('ajaxSuccess.formsDataLayerSubmit', function (e, xhr) {
                        const responseJSON = xhr.responseJSON;
                        // Refactor after GSD-2562
                        let resFieldsIsEmpty = true;

                        /**
                         * This is a safeguard to alert devs in case something alters the form focus and thus the data layer before this event fires.
                         * The previous example was an Ajax call's "success" handler, which set focus to a field on the next form.
                         * If such is needed, the Ajax call's "complete" handler should be used to set focus.
                         */
                        if (digitalData.page.form.formName !== formName) {
                            // eslint-disable-next-line no-console
                            console.error(`formsDataLayer-ajaxSuccess > unexpected form name in digitalData. Actual: ${digitalData.page.form.formName}, Expected: ${formName}`);
                        }

                        if (responseJSON && responseJSON.fields) {
                            resFieldsIsEmpty = Object.keys(responseJSON.fields).length === 0;
                        }

                        if (!responseJSON || (responseJSON.success !== false && !responseJSON.error && resFieldsIsEmpty)) {
                            if (typeof _satellite !== 'undefined') {
                                // Cloning digitalData so the event has the state at the moment it was fired
                                _satellite.track('formComplete', { digitalData: window.digitalData ? JSON.parse(JSON.stringify(window.digitalData)) : undefined });
                            }
                        }
                    });

                    // Always turn off the event registration regardless of success/failure
                    $(document).on('ajaxComplete.formsDataLayerSubmit', function () {
                        $(document).off('.formsDataLayerSubmit');
                    });
                }
            });
    } else {
        console.warn('Warning: DigitalData is turned on, but has not been added to this page.'); // eslint-disable-line no-console
    }
}

$(document).ready(function () {
    initFormTracking();
}).on('form:created', function (event) {
    initFormTracking($(event.target));
});
